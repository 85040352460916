import AppConfig from '../core/config';

const fetchSOFAvailableCheck = async () => {
	    const url = `${AppConfig.api_base_url}/soflog/sof-available-check`;

	    const response = await fetch(url, {
		            method: 'POST',
		            credentials: 'include',
		        });

	    return response;
}


const loggingSOFAccess = async (logInfo) => {
    const url = `${AppConfig.api_base_url}/soflog/logging/${logInfo}`;

    const response = await fetch(url, {
        method: 'GET',
        credentials: 'include',
    });

    return response;
};

const fetchIncrementAccessCount = async (type) => {
    const url = `${AppConfig.api_base_url}/soflog/increment-access-count`;

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ type: type }),
    });

    return response;
};

const fetchSetStartTime = async (type) => {
    const url = `${AppConfig.api_base_url}/soflog/set-start-time`;

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ type: type }),
    });

    return response;
};

const fetchSetEndTime = async (type) => {
    const url = `${AppConfig.api_base_url}/soflog/set-end-time`;

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ type: type }),
    });

    return response;
};

const fetchSOFLogs = async () => {
    const url = `${AppConfig.api_base_url}/soflog/get-all`;

    const response = await fetch(url, {
        method: 'GET',
        credentials: 'include',
    });

    return response;
};

const fetchHomeworkLogs = async () => {
    const url = `${AppConfig.api_base_url}/soflog/get/access-log/all`;

    const response = await fetch(url, {
        method: 'GET',
        credentials: 'include',
    });

    return response;
};

const fetchIsSOFComplete = async () => {
    const url = `${AppConfig.api_base_url}/soflog/get/is-complete-sof`;

    const response = await fetch(url, {
        method: 'GET',
        credentials: 'include',
    });

    return response;
};

export {
    loggingSOFAccess,
    fetchIncrementAccessCount,
    fetchSetStartTime,
    fetchSetEndTime,
    fetchSOFLogs,
    fetchHomeworkLogs,
    fetchIsSOFComplete,
    fetchSOFAvailableCheck
};
